import React, { PropsWithChildren, createContext, useState } from 'react'
import { Language } from '@/assets/language/types'

export type LanguageContextValue = {
    language: Language
    languageCode: string
    setLanguage(languageCode: string): void
}

type LanguageState = {
    code: string
    language: Language
}

const languages: { [key: string]: () => Language } = {
    'cs': () => require('@/assets/language/cs.json'),
    'de': () => require('@/assets/language/de.json'),
    'fr': () => require('@/assets/language/fr.json'),
    'en': () => require('@/assets/language/en.json'),
    'es': () => require('@/assets/language/es.json'),
    'it': () => require('@/assets/language/it.json'),
    'nl': () => require('@/assets/language/nl.json'),
    'pl': () => require('@/assets/language/pl.json')
}

const defaultLanguageCode = 'en'
const defaultLanguage = languages[defaultLanguageCode]()

const LanguageContext = createContext<LanguageContextValue>({
    language: defaultLanguage,
    languageCode: defaultLanguageCode,
    setLanguage: () => {}
})

const LanguageProvider = ({ children }: PropsWithChildren) => {

    const [ language, _setLanguage ] = useState<LanguageState>({ code: defaultLanguageCode, language: defaultLanguage })

    const setLanguage = (languageCode: string) => {
        if (languages[languageCode]) {
            _setLanguage({ code: languageCode, language: languages[languageCode]() })
        }
    }

    const value = {
        language: language.language,
        languageCode: language.code,
        setLanguage
    }

    return (
        <LanguageContext.Provider value={value}>
            {children}
        </LanguageContext.Provider>
    )

}

export { LanguageProvider }
export default LanguageContext
