import React, { useMemo } from 'react'
import bold from '@/utils/bold-sdk'
import { Link } from 'react-router-dom'
import { Device } from '@/utils/bold-sdk'
import { cn } from '@/utils/utils'
import { ReactComponent as IconClicker } from '@/assets/icons/clicker.svg'
import { ReactComponent as IconLock } from '@/assets/icons/lock.svg'
import { WifiIcon } from 'lucide-react'
import styles from './styles.module.scss'

export type DeviceIconNameProps = {
    as?: string
    device: Partial<Device> & { id: number }
    iconSize?: number
    useImage?: boolean
    link?: boolean
    linkTo?: string
    className?: string
    onClick?(): void
}

const DeviceIconName = ({ as = 'span', device, iconSize = 32, useImage = true, link = true, linkTo, className, onClick = () => {} }: DeviceIconNameProps) => {

    const Component = as as keyof JSX.IntrinsicElements

    const url = useMemo(() => linkTo ?? `/devices/${device.id}`, [ linkTo, device.id ])

    const imageURL = useMemo(() =>
        Boolean(useImage && device.carouselImageUrl)
            ? `${device.carouselImageUrl}?token=${bold.getAccessToken()}`
            : 'https://resources.boldsmartlock.com/carousel-device-images/door_1.jpg'
    , [ useImage, device.carouselImageUrl ])

    const icon = useMemo(() => {
        switch (device.deviceModel?.deviceType.name) {
            case 'Keyfob': return (
                <IconClicker
                    width={iconSize * 0.54}
                    height={iconSize * 0.54}
                    className={styles.svg}
                />
            )
            case 'Gateway': return (
                <WifiIcon
                    size={iconSize * 0.54}
                    stroke="#ffffff"
                />
            )
        }
        return (
            <IconLock
                width={iconSize * 0.54}
                height={iconSize * 0.54}
                className={styles.svg}
            />
        )
    }, [ device.deviceModel?.deviceType.name, iconSize, styles ])

    return (
        <div
            className={cn(styles.iconName, className)}
            onClick={onClick}
        >
            <div
                className={styles.iconDevice}
                style={{
                    backgroundImage: imageURL ? `url(${imageURL})` : undefined,
                    minWidth: iconSize,
                    width: iconSize,
                    height: iconSize
                }}
            >
                {!imageURL ? icon : null}
            </div>
            <div className={styles.name} style={{ marginInlineStart: iconSize * 0.3 }}>
                {link ? (
                    <Link to={url}><Component>{device.name}</Component></Link>
                ) : (
                    <Component>{device.name}</Component>
                )}
            </div>
        </div>
    )

}

export default DeviceIconName
