import React, { Suspense } from 'react'
import { BrowserRouter, Routes } from 'react-router-dom'
import { LanguageProvider } from './contexts/LanguageContext'
import { NotificationProvider } from '@/contexts/NotificationContext'
import { AccountProvider } from '@/contexts/AccountContext'
import { OrganizationProvider } from '@/contexts/OrganizationContext'
import { ConfirmModalProvider } from './contexts/ConfirmModalContext'
import { ShareControlProvider } from '@/contexts/ShareControlContext'
import Container from '@/components/container'
import Spinner from '@/components/spinner'
import { routes } from './routes'

const App = (): JSX.Element => {

	return (
        <BrowserRouter>
            <LanguageProvider>
                <NotificationProvider>
                    <AccountProvider >
                        <OrganizationProvider>
                            <ConfirmModalProvider>
                                <ShareControlProvider>
                                    <Container>
                                        <Suspense fallback={<Spinner center />}>
                                            <Routes>
                                                {routes}
                                            </Routes>
                                        </Suspense>
                                    </Container>
                                </ShareControlProvider>
                            </ConfirmModalProvider>
                        </OrganizationProvider>
                    </AccountProvider>
                </NotificationProvider>
            </LanguageProvider>
        </BrowserRouter>
	)

}


export default App
