import '@/utils/prototypes'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './styles/general.scss'
import './styles/theme.scss'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(<App />)
