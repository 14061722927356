import React, { useMemo, PropsWithChildren } from 'react'
import { v4 as uuid } from 'uuid'
import { generateColorHsl } from '@/utils/colors'
import styles from './styles.module.scss'

type ColorIconProps = {
    id: number
    size: number
    shadow?: boolean
}

const ColorIcon = ({ children, id, size, shadow = false }: PropsWithChildren<ColorIconProps>) => {

    const uniqueId = useMemo(() => uuid(), [])

    const [ fromColor, toColor ] = useMemo(() => [
        id ? generateColorHsl(''+(id * 9)) : 'red',
        id ? generateColorHsl(''+((id * 9) + 100)) : 'red'
    ], [ id ])

    const boxShadow = useMemo(() =>
        shadow ? `0 3px 12px 0 ${fromColor.replace(')', ',0.15)')}` : undefined
    , [ shadow, fromColor ])

    return (
        <div
            className={styles.colorIcon}
            style={{
                width: size,
                height: size,
                boxShadow
            }}
        >
            <svg width={size} height={size} className={styles.gradient}>
                <defs>
                    <linearGradient id={'grad' + uniqueId} x1="0%" y1="0%" x2="100%" y2="100%">
                        <stop offset="0" stopColor={fromColor} />
                        <stop offset="1" stopColor={toColor} />
                    </linearGradient>
                </defs>
                <rect width="100%" height="100%" fill={`url(#grad${uniqueId})`}/>
            </svg>
            {children}
        </div>
    )

}

export default ColorIcon
