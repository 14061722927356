import { format, isToday, isThisYear } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { cs, de, enGB, es, fr, it, nl, pl } from 'date-fns/locale'

const getLocale = (languageCode: string) => {
    switch (languageCode) {
        case 'cs': return cs
        case 'de': return de
        case 'en': return enGB
        case 'es': return es
        case 'fr': return fr
        case 'it': return it
        case 'nl': return nl
        case 'pl': return pl
    }
    return enGB
}

type ParseDateTimeOptions = {
    languageCode: string
    timeZone?: string
}

export const parseDateTime = (date: Date, { languageCode, timeZone }: ParseDateTimeOptions): string => {
    const locale = getLocale(languageCode)
    if (timeZone) date = utcToZonedTime(date, timeZone)
    if (isToday(date)) return format(new Date(date), 'HH:mm', { locale })
    else if (isThisYear(date)) return format(new Date(date), 'MMM d HH:mm', { locale })
    else return format(new Date(date), 'MMM d, yyyy HH:mm', { locale })
}
