import Bold, { Environment } from 'bold-javascript-sdk'

const bold = new Bold({
    environment: process.env.REACT_APP_ENVIRONMENT as Environment,
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    clientSecret: process.env.REACT_APP_CLIENT_SECRET as string,
    authRefresh: true,
    debug: false
})

export * from 'bold-javascript-sdk'

export default bold
