import { User } from '@/utils/bold-sdk'

export type UserNameInfo = {
    fullName: string
    accountName?: string
    firstName?: string
    lastName?: string
}

export function getUserNameInfo(user?: Partial<User>, options?: { shorten?: boolean }): UserNameInfo {
    let fullName = 'Unnamed User'
    let accountName: string | undefined = undefined
    let firstName: string | undefined = undefined
    let lastName: string | undefined = undefined
    if (user) {
        if (user.firstName || user.lastName) {
            if (user.firstName) firstName = user.firstName
            if (user.lastName) lastName = user.lastName
        } else if (user.account && (user.account.firstName || user.account.lastName)) {
            if (user.account.firstName) firstName = user.account.firstName
            if (user.account.lastName) lastName = user.account.lastName
        }
        if (user.account && user.account.firstName) accountName = [user.account.firstName,user.account.lastName].join(' ')
    }
    if (firstName && firstName.length > 0 && options?.shorten) firstName = firstName.substring(0, 1) + '.'
    if (firstName || lastName) fullName = [firstName,lastName].join(' ')
    return { fullName, accountName, firstName, lastName }
}
