export const getBrowser = (): { name: string, version?: string } | null => {
    const userAgent = window?.navigator?.userAgent
    if (userAgent) {
        if (/Chrome/.test(userAgent)) return { name: 'Google Chrome' }
        else if (/Edge/.test(userAgent)) return { name: 'Microsoft Edge' }
        else if (/Firefox/.test(userAgent)) return { name: 'Firefox' }
        else if (/Opera/.test(userAgent)) return { name: 'Opera' }
        else if (/Safari/.test(userAgent)) return { name: 'Safari' }
    }
    return null
}
