import React, { useState, useEffect, PropsWithChildren, useCallback } from 'react'
import bold, { AccountRich, OAuthData } from '@/utils/bold-sdk'
import useLanguage from '@/hooks/useLanguage'
import useNotification from '@/hooks/useNotification'
import Spinner from '@/components/spinner'
import { STORAGE_KEY } from '@/utils/constants'
import { getBrowser } from '@/utils/browser'
// import Agreement from '@/components/agreement'

export interface AccountContextValue {
    data?: AccountRich
    preferences: Preferences
    colorScheme: ColorScheme
    setPreferences(preferences: Partial<Preferences>): void
    signOut: () => void
}

type ColorScheme = 'light' | 'dark'

export type Preferences = {
    deviceListLocksMode: 'grid' | 'table'
    colorScheme: 'auto' | ColorScheme
    userInviteMethod?: 'email' | 'sms'
}

const preferencesDefault: Preferences = {
    deviceListLocksMode: 'grid',
    colorScheme: 'auto'
}

const AccountContext = React.createContext<AccountContextValue>({
    data: undefined,
    preferences: preferencesDefault,
    colorScheme: 'light',
    setPreferences: () => {},
    signOut: () => { void 0; }
})

const AccountProvider = ({ children }: PropsWithChildren) => {

    const { setLanguage } = useLanguage()
    const { handleError } = useNotification()
    const [ accountData, setAccountData ] = useState<AccountRich>()
    const [ preferences, _setPreferences ] = useState<Preferences>(preferencesDefault)
    const [ colorScheme, setColorScheme ] = useState<'dark' | 'light'>(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string
    const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET as string
    const DEV_MODE = window.location.hostname === 'localhost'
    const authAppURL = CLIENT_ID === 'BoldPortal'
        ? 'https://auth.boldsmartlock.com'
        : 'https://auth-staging.boldsmartlock.com'

    const setClientInstance = async (accountId: number) => {
        try {
            const browser = getBrowser()
            if (browser) {
                await bold.account.setClientInstance(accountId, {
                    osType: 'Browser',
                    osVersion: browser.version?.toString(),
                    appVersion: process.env.REACT_APP_VERSION,
                    description: browser.name
                })
            }
        } catch (error) {
            console.warn(error)
        }
    }

    const authenticate = async (auth: OAuthData) => {
        window.localStorage.setItem(STORAGE_KEY.AUTH, JSON.stringify(auth))
        const { data: account } = await bold.account.get()
        setAccountData(account)
        setClientInstance(account.id)
        if (account.language) setLanguage(account.language)
    }

    const signOut = () => {
        Object.values(STORAGE_KEY).forEach(key => window.localStorage.removeItem(key))
        openAuthApp(true)
    }

    const openAuthApp = (logout: boolean = false) => {
        const redirectUri = window.location.protocol + '//' + window.location.host
        window.location.href = `${authAppURL}/${logout ? 'logout' : ''}?client_id=${CLIENT_ID}&response_type=code&state=auth&redirect_uri=${redirectUri}`
    }

    const authWithCode = async (code: string) => {
        let redirectURI = 'https://portal-staging.boldsmartlock.com'
        if (CLIENT_ID === 'BoldPortal') redirectURI = 'https://portal.boldsmartlock.com'
        const auth = await bold.authenticate({
            grantType: 'authorization_code',
            code,
            redirectURI: redirectURI,
            clientId: CLIENT_ID,
            clientSecret: CLIENT_SECRET
        })
        await authenticate(auth)
    }

    const reAuthenticate = async () => {
        try {
            const query = new URLSearchParams(location.search)
            const authJSON = window.localStorage.getItem(STORAGE_KEY.AUTH)
            const auth: OAuthData = authJSON ? JSON.parse(authJSON) : null
            const authCode = query.get('code')
            if (authCode) {
                await authWithCode(authCode)
            }
            else if (auth) {
                auth.expires = new Date(auth.expires as unknown as string)
                bold.setAuthentication(auth)
                await authenticate(auth)
            }
            else if (!DEV_MODE) {
                openAuthApp()
            }
        } catch (error) {
            if (error.response?.status === 401 && !DEV_MODE) {
                openAuthApp()
            } else {
                handleError(error)
            }
        }
    }

    const loadPreferences = useCallback(() => {
        const json = window.localStorage.getItem(STORAGE_KEY.PREFERENCES)
        if (json) _setPreferences({...preferences,...JSON.parse(json)})
    }, [ _setPreferences ])

    const setPreferences = (newPreferences: Partial<Preferences>) => {
        const newState = { ...preferences, ...newPreferences }
        window.localStorage.setItem(STORAGE_KEY.PREFERENCES, JSON.stringify(newState))
        _setPreferences(newState)
    }

    const handleSystemThemeChangeEvent = (event: MediaQueryListEvent) => {
        setColorScheme(event.matches ? 'dark' : 'light')
    }

    useEffect(() => {
        // Implement fake loading delay
        const requestInterceptor = bold.intercept.request(async config => {
            // @ts-ignore
            config.metadata = { startTime: new Date() }
            return config
        })
        const responseInterceptor = bold.intercept.response(async response => {
            const endTime = new Date()
            // @ts-ignore
            const duration = endTime - response.config.metadata.startTime
            let target = 300
            if (response.config.method === 'put' || response.config.method === 'post') target = 1400
            if (duration < target) await new Promise(r => setTimeout(r, target - duration))
            return response
        })
        return () => {
            requestInterceptor.remove()
            responseInterceptor.remove()
        }
    }, [])

    useEffect(() => {
        const unsubscribe = bold.onAuthenticationChanged(auth => {
            window.localStorage.setItem(STORAGE_KEY.AUTH, JSON.stringify(auth))
        })
        reAuthenticate().catch(console.error)
        return () => {
            unsubscribe()
        }
    }, [])

    useEffect(() => {
        loadPreferences()
    }, [ loadPreferences ])

    useEffect(() => {
        if (preferences) {
            if (preferences.colorScheme === 'auto') {
                window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handleSystemThemeChangeEvent)
            }
            else if (preferences.colorScheme !== colorScheme) {
                setColorScheme(preferences.colorScheme)
            }
        }
        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', handleSystemThemeChangeEvent)
        }
    }, [ preferences, colorScheme ])

    useEffect(() => {
        const body = window.document.querySelector('body')
        // if (body) body.setAttribute('data-theme', colorScheme)
    }, [ colorScheme ])

    if (!accountData) return <Spinner center />

    const value: AccountContextValue = {
        data: accountData,
        preferences,
        colorScheme,
        setPreferences,
        signOut
    }

    return (
        <AccountContext.Provider value={value}>
            {children}
            {/* {accountData !== undefined && <Agreement account={accountData} onAccept={refreshAccountData} />} */}
        </AccountContext.Provider>
    )

}

export { AccountProvider }
export const AccountConsumer = AccountContext.Consumer
export default AccountContext
