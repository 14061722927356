import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import useLanguage from '@/hooks/useLanguage'
import useAccount from '@/hooks/useAccount'
import useOrganization from '@/hooks/useOrganization'
import UserIcon from '@/components/icon-name/initials-icon'
import { Switch } from '@/components/ui/switch'
import { STORAGE_KEY } from '@/utils/constants'
import { ReactComponent as IconAngleDown } from '@/assets/icons/angle-down.svg'
import styles from './styles.module.scss'

const UserMenu = () => {

    const navigate = useNavigate()
    const { language } = useLanguage()
    const { data: account, colorScheme, setPreferences, signOut } = useAccount()
    const { organization, accountUser, setAccountUser } = useOrganization()
    const [ userMenuVisible, setUserMenuVisible ] = useState(false)

    const organizations = useMemo(() =>
        account ?
            account.users
                .filter(u => !u.organization.personal && !u.organization.restricted)
                .map(u => u.organization) : []
    , [ account ])

    useEffect(() => {
        setTimeout(() => {
            window.document.addEventListener('click', handleWindowClick)
        }, 100)
        return () => {
            window.document.removeEventListener('click', handleWindowClick)
        }
    }, [ userMenuVisible ])

    const handleWindowClick = (event: MouseEvent) => {
        event.stopPropagation()
        if (userMenuVisible) setUserMenuVisible(false)
    }

    const toggleUserMenu = () => {
        setUserMenuVisible(!userMenuVisible)
    }

    // const onToggleTheme = (value: boolean) => {
    //     setPreferences({ colorScheme: value ? 'dark' : 'light' })
    // }

    const onClickOrganization = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>, orgId: number | null) => {
        event.preventDefault()
        if (!account) return
        const accountUser = account.users.find(ou => ou.organization.id === orgId)
        setUserMenuVisible(false)
        if (accountUser || orgId === null) {
            setAccountUser(accountUser || null)
            if (orgId === null) navigate('/devices')
        }
    }, [ history, account ])

    return (
        <div className={styles.userProfileContainer}>
            <div className={styles.userProfile} onClick={toggleUserMenu}>
                <div className={styles.accountInfo}>
                    <span className={styles.accountName}>{account?.firstName} {account?.lastName}</span><br />
                    {organization !== null &&
                        <span className={styles['org-name']}>{organization.name}</span>
                    }
                </div>
                <UserIcon
                    id={accountUser?.id ?? account?.id}
                    firstName={account?.firstName}
                    lastName={account?.lastName}
                    className={styles.userIcon}
                    size={38}
                    tooltip={false}
                />
                <IconAngleDown width={25} height={25} />
            </div>
            {userMenuVisible && (
                <div className={styles.userMenu}>
                    {/* <div className={styles.option}>
                        <Switch checked={colorScheme === 'dark'} onCheckedChange={onToggleTheme} />
                    </div> */}
                    <div className={styles.orgs}>
                        {organizations.map(org => (
                            <div
                                key={org.id}
                                className={styles.org}
                                onClick={event => onClickOrganization(event, org.id)}
                            >
                                {org.name}
                            </div>
                        ))}
                    </div>
                    <Link to="/my-account" className={styles.option}>
                        {language.myAccount}
                    </Link>
                    <div onClick={signOut} className={styles.option}>
                        {language.signOut}
                    </div>
                </div>
            )}
        </div>
    )

}

export default UserMenu
