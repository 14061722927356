import React, { createContext, useCallback, useRef, useState, PropsWithChildren } from 'react'
import { ReactComponent as IconCheckCircle } from '@/assets/icons/check-circle.svg'
import { ReactComponent as IconExclamationOctagon } from '@/assets/icons/exclamation-octagon.svg'
import styles from '../styles/notification.module.scss'

export interface NotificationContextValue {
    success(text: string): void
    error(text: string): void
    handleError(error: any): void
}

type Notification = {
    id: string
    text: string
    style: 'error' | 'success'
}

const NotificationContext = createContext<NotificationContextValue>({
    success: () => {},
    error: () => {},
    handleError: () => {}
})

const NotificationProvider = ({ children }: PropsWithChildren<{}>) => {

    const [ notifications, setNotifications ] = useState<Notification[]>([])
    const notificationsRef = useRef<Notification[]>([])
    const timer = useRef<NodeJS.Timeout>()

    const rand = useCallback((): string => {
        return Date.now().toString() + Math.floor(Math.random() * 100).toString()
    }, [])

    const notify = useCallback((notification: Notification) => {
        notificationsRef.current = [notification,...notificationsRef.current].slice(0,3)
        setNotifications(notificationsRef.current.slice(0, 10))
        if (timer.current) clearTimeout(timer.current)
        timer.current = setTimeout(() => {
            notificationsRef.current = []
            setNotifications([])
        }, 6.5 * 1000)
    }, [ setNotifications, timer.current ])

    const error = useCallback((text: string) => notify({
        id: rand(),
        text,
        style: 'error'
    }), [ notify, rand ])

    const success = useCallback((text: string) => notify({
        id: rand(),
        text,
        style: 'success'
    }), [ notify, rand ])

    const handleError = useCallback((err: any) => {
        if (err.response?.data) {
            error(
                err.response.data.errorMessage
                ?? err.response.data.message
                ?? `Something went wrong (${err.response.status})`
            )
        } else {
            error(err.message ?? 'Something went wrong')
        }
    }, [ error ])

    const value = {
        success,
        error,
        handleError
    }

    return (
        <NotificationContext.Provider value={value}>
            {children}
            <div className={styles['notification-container']}>
                {notifications.map(({ id, text, style }) => {
                    let className = styles.notification
                    let icon = <></>
                    switch (style) {
                        case 'error':
                            className += ' ' + styles.error
                            icon = <IconExclamationOctagon width={24} height={24} />
                            break
                        case 'success':
                            className += ' ' + styles.success
                            icon = <IconCheckCircle width={24} height={24} />
                            break
                    }
                    return (
                        <div
                            key={id}
                            className={className}
                        >
                            <div className={styles.prefix}>
                                {icon}
                            </div>
                            <div className={styles.text}>
                                {text}
                            </div>
                        </div>
                    )
                })}
            </div>
        </NotificationContext.Provider>
    )

}

export { NotificationProvider }

export default NotificationContext
