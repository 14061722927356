import React, { useEffect, useState, PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'
import Button from '@/components/button'
import { LoaderIcon, XIcon } from 'lucide-react'
import { cn } from '@/utils/utils'
import styles from './styles.module.scss'

export type ModalAction = {
    name: string
    buttonType?: 'button' | 'submit' | 'reset'
    style?: 'primary' | 'red'
    loading?: boolean
    onClick?(): void
}

export type ModalActions = Array<ModalAction>

export type ModalProps = {
    visible: boolean
    title?: string
    closeable?: boolean
    actions?: ModalActions
    width?: number
    scrollable?: boolean
    onClose?(visible: false): void
}

export default function Modal({ children, visible, title, actions = [], width = 420, scrollable = false, onClose = () => {} }: PropsWithChildren<ModalProps>) {

    const [ _visible, _setVisible ] = useState(visible)

    const close = () => {
        _setVisible(false)
        onClose(false)
    }

    const handleModalClick = (event: React.MouseEvent<HTMLDivElement | HTMLFormElement, MouseEvent>) => {
        event.stopPropagation()
    }

    useEffect(() => {
        if (visible && !_visible) _setVisible(true)
        else if (!visible && _visible) _setVisible(false)
    }, [ visible, _visible ])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (visible && event.key === 'Escape') {
                close()
            }
        }
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [ visible ])

    if (!_visible) return null

    const content = <>
        <header className={styles.modalHeader}>
            <div className="font-medium">{title}</div>
            <div className="h-12 w-6 flex items-center justify-center" onClick={close}>
                <XIcon className="w-5 h-5" />
            </div>
        </header>
        <div className={styles.modalContent}>
            {children}
        </div>
        {actions.length > 0 && (
            <footer className="flex p-6 gap-2 justify-end">
                {actions.map((action, index) => (
                    <div key={'action-'+(index + 1)}>
                        <Button
                            type={action.buttonType}
                            orange={action.style === 'primary'}
                            red={action.style === 'red'}
                            onClick={action.onClick}
                        >
                            {action.loading && <LoaderIcon className="me-2 w-[19px] h-[19px] animate-spin" />}
                            {action.name}
                        </Button>
                    </div>
                ))}
            </footer>
        )}
    </>

    return ReactDOM.createPortal(
        <div className={styles.container} /*onClick={close}*/>
            <div
                className={cn(styles.modal, scrollable ? styles.scrollable : '')}
                style={{ maxWidth: width, width }}
                children={content}
                onClick={handleModalClick}
            />
        </div>,
        document.querySelector("#modal")!
    )

}
