import React from 'react'
import { Navigate, Route, RouteProps } from 'react-router-dom'

const AdminAccount = React.lazy(() => import(/* webpackChunkName: "adminAccount" */ '@/pages/admin/accounts/account'))
const AdminAccounts = React.lazy(() => import(/* webpackChunkName: "adminAccounts" */ '@/pages/admin/accounts'))
const AdminDevice = React.lazy(() => import(/* webpackChunkName: "adminDevice" */ '@/pages/admin/devices/device'))
const AdminDevices = React.lazy(() => import(/* webpackChunkName: "adminDevices" */ '@/pages/admin/devices'))
const AdminOrganization = React.lazy(() => import(/* webpackChunkName: "adminOrganization" */ '@/pages/admin/organizations/organization'))
const AdminOrganizations = React.lazy(() => import(/* webpackChunkName: "adminOrganizations" */ '@/pages/admin/organizations'))
const Device = React.lazy(() => import(/* webpackChunkName: "device" */ '@/pages/devices/device'))
const DeviceEvents = React.lazy(() => import(/* webpackChunkName: "deviceEvents" */ '@/pages/device-events'))
const Devices = React.lazy(() => import(/* webpackChunkName: "devices" */ '@/pages/devices'))
const DeviceGroup = React.lazy(() => import(/* webpackChunkName: "deviceGroup" */ '@/pages/device-groups/device-group'))
const DeviceGroups = React.lazy(() => import(/* webpackChunkName: "deviceGroups" */ '@/pages/device-groups'))
const MyAccount = React.lazy(() => import(/* webpackChunkName: "myAccount" */ '@/pages/my-account'))
const NotFound = React.lazy(() => import(/* webpackChunkName: "notFound" */ '@/pages/404'))
const OrganizationDeveloper = React.lazy(() => import(/* webpackChunkName: "organizationDeveloper" */ '@/pages/organization/developer'))
const Settings = React.lazy(() => import(/* webpackChunkName: "settings" */ '@/pages/settings/settings'))
const User = React.lazy(() => import(/* webpackChunkName: "user" */ '@/pages/users/user'))
const UserGroup = React.lazy(() => import(/* webpackChunkName: "userGroup" */ '@/pages/user-groups/user-group'))
const UserGroups = React.lazy(() => import(/* webpackChunkName: "userGroups" */ '@/pages/user-groups'))
const Users = React.lazy(() => import(/* webpackChunkName: "users" */ '@/pages/users'))

const CustomRoute = (props: React.PropsWithChildren<RouteProps>[]): React.ReactElement[] => {
    return props.map(props => <Route key={props.path} {...props} />)
}

export const routes = CustomRoute([
    {
        path: '/',
        element: <Navigate to="/devices" />
    },
    {
        path: '/devices',
        children: CustomRoute([
            {
                path: '',
                element: <Devices />
            },
            {
                path: 'locks',
                element: <Devices />
            },
            {
                path: 'connects',
                element: <Devices />
            },
            {
                path: 'clickers',
                element: <Devices />
            },
            {
                path: ':id/*',
                element: <Device />
            }
        ])
    },
    {
        path: '/device-groups',
        children: CustomRoute([
            {
                path: '',
                element: <DeviceGroups />
            },
            {
                path: ':id/*',
                element: <DeviceGroup />
            }
        ])
    },
    {
        path: '/device-events',
        element: <DeviceEvents />
    },
    {
        path: '/users',
        children: CustomRoute([
            {
                path: '',
                element: <Users />
            },
            {
                path: ':id/*',
                element: <User />
            }
        ])
    },
    {
        path: '/user-groups',
        children: CustomRoute([
            {
                path: '',
                element: <UserGroups />,
            },
            {
                path: ':id/*',
                element: <UserGroup />
            }
        ])
    },
    {
        path: '/my-account',
        element: <MyAccount />
    },
    {
        path: '/settings',
        element: <Settings />
    },
    {
        path: '/organization',
        children: CustomRoute([
            {
                path: 'developer',
                element: <OrganizationDeveloper />
            }
        ])
    },
    {
        path: '/admin',
        children: CustomRoute([
            {
                path: 'organizations',
                children: CustomRoute([
                    {
                        path: '',
                        element: <AdminOrganizations />
                    },
                    {
                        path: ':id/*',
                        element: <AdminOrganization />
                    }
                ])
            },
            {
                path: 'accounts',
                children: CustomRoute([
                    {
                        path: '',
                        element: <AdminAccounts />
                    },
                    {
                        path: ':id/*',
                        element: <AdminAccount />
                    }
                ])
            },
            {
                path: 'devices',
                children: CustomRoute([
                    {
                        path: '',
                        element: <AdminDevices />
                    },
                    {
                        path: ':id/*',
                        element: <AdminDevice />
                    }
                ])
            }
        ])
    },
    {
        path: '*',
        element: <NotFound />
    }
])
