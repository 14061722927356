import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { User } from '@/utils/bold-sdk'
import useLanguage from '@/hooks/useLanguage'
import InitialsIcon from './initials-icon'
import { getUserNameInfo } from '@/utils/user'
import styles from './styles.module.scss'
import { cn } from '@/utils/utils'
import { parseDateTime } from '@/utils/date'
import { interpolateString } from '@/utils/_string'

export type UserIconNameProps = {
    as?: string
    user: Partial<User> & { id: number }
    iconSize?: number
    link?: boolean
    shorten?: boolean
    showAdminIcon?: boolean
    showAccountName?: boolean
    showInviteExpiration?: boolean
    className?: string
    onClick?(): void
}

export default function UserIconName({ as = 'span', user, iconSize = 32, link = true, shorten = false, showAdminIcon, showAccountName, showInviteExpiration, className, onClick = () => {} }: UserIconNameProps) {

    const { language, languageCode } = useLanguage()
    const Component = as as keyof JSX.IntrinsicElements

    const name = useMemo(() =>
        user
            ? getUserNameInfo(user, { shorten })
            : { firstName: 'Unnamed', lastName: 'User', fullName: 'Unnamed User' }
    , [
        user.firstName,
        user.lastName,
        user.account?.firstName,
        user.account?.lastName,
        shorten
    ])

    const url = useMemo(() => `/users/${user.id}`, [ user ])

    const description = useMemo(() => {
        if (showInviteExpiration && user.invitation?.expiration) {
            const dt = parseDateTime(new Date(user.invitation.expiration), { languageCode })
            return interpolateString(language.inviteExpiresTime, dt)
        } else if (showAccountName && name.accountName !== name.fullName) {
            return name.accountName
        } else {
            return null
        }
    }, [ showInviteExpiration, showAccountName, name, user.invitation, language, languageCode ])

    return (
        <div
            className={cn(styles.iconName,className)}
            onClick={onClick}
        >
            <InitialsIcon
                id={user.id}
                firstName={user.firstName || user.account?.firstName}
                lastName={user.lastName || user.account?.lastName}
                size={iconSize}
                tooltip={false}
                showPendingIcon={user.invitation !== undefined}
                showAdminIcon={showAdminIcon}
                onClick={undefined}
            />
            <div className="overflow-x-hidden text-ellipsis whitespace-nowrap" style={{ marginInlineStart: iconSize * 0.3 }}>
                {link
                    ? <Link to={url}><Component>{name.fullName}</Component></Link>
                    : <Component>{name.fullName}</Component>
                }
                {description && (
                    <div className="text-sm text-secondary">
                        {description}
                    </div>
                )}
            </div>
        </div>
    )

}
