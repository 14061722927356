import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import Tooltip from '@/components/tooltip'
import ColorIcon from './color-icon'
import styles from './styles.module.scss'
import { Clock4Icon, SettingsIcon } from 'lucide-react'
import { cn } from '@/utils/utils'

export type InitialsIconProps = {
    id?: number
    firstName?: string
    lastName?: string
    tooltipUrl?: string
    tooltip?: boolean
    size?: number
    shadow?: boolean
    showPendingIcon?: boolean
    showAdminIcon?: boolean
    className?: string
    onClick?(): void
}

const InitialsIcon = ({ id = -1, firstName, lastName, tooltipUrl, tooltip = true, size = 32, shadow = false, showPendingIcon = false, showAdminIcon = false, className, onClick }: InitialsIconProps) => {

    const str = useMemo(() => {
        let str = ''
        if (firstName && lastName) {
            str = [firstName.substring(0, 1).toUpperCase(),lastName.substring(0, 1).toUpperCase()].join('')
        }
        else if (firstName || lastName) {
            const initials = (firstName || lastName)!
                .normalize('NFD')
                .replace(/[^A-Za-z ]/g, '')
                .split(' ')
                .map(str => str[0])
            if (initials.length > 1) str = [[...initials].shift(),[...initials].pop()].join('').toUpperCase()
            else str = initials[0]?.toUpperCase()
        }
        return str
    }, [ firstName, lastName ])

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (onClick) {
            event.stopPropagation()
            onClick()
        }
    }

    const icon = useMemo<(() => JSX.Element) | null>(() => {
        if (showPendingIcon) return () => <Clock4Icon size={size * 0.36} className="icon" />
        if (showAdminIcon) return () => <SettingsIcon size={size * 0.36} className="icon" />
        return null
    }, [ size, showPendingIcon, showAdminIcon ])

    return (
        <Tooltip
            active={tooltip}
            offset={{ left: -7 }}
            render={() => (
                <div className={styles.info} style={{ top: size - 6 }}>
                    <Link to={tooltipUrl ?? '#'}>{firstName} {lastName}</Link>
                </div>
            )}
        >
            <div
                className={cn(styles.userIcon,className)}
                style={{ minWidth: size, width: size, height: size }}
                onClick={onClick ? handleClick : undefined}
            >
                <ColorIcon
                    id={id}
                    size={size}
                    shadow={shadow}
                >
                    <span
                        className={styles.initials}
                        style={{ fontSize: size * 0.36 }}
                    >
                        {str}
                    </span>
                </ColorIcon>
                {icon && (
                    <div
                        className={cn('flex absolute rounded-full items-center justify-center',styles.detail)}
                        style={{
                            bottom: -(size * 0.1),
                            height: size * 0.47,
                            right: -(size * 0.1),
                            width: size * 0.47
                        }}
                    >
                        {icon()}
                    </div>
                )}
            </div>
        </Tooltip>
    )

}

export default InitialsIcon
