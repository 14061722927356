import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState, PropsWithChildren } from 'react'
import styles from './styles.module.scss'

export type TooltipProps = {
    active?: boolean
    method?: 'hover' | 'click'
    offset?: { top?: number, right?: number, bottom?: number, left?: number }
    delay?: number
    padding?: boolean
    style?: any
    render(): JSX.Element | string | null
}

const Tooltip = forwardRef(({
    children,
    active = true,
    method = 'hover',
    offset = {},
    delay,
    padding = true,
    style = {},
    render
}: PropsWithChildren<TooltipProps>, ref) => {

    const [ visible, setVisible ] = useState(false)
    const delayTimer = useRef<NodeJS.Timeout | null>(null)

    const clearTimer = () => {
        if (delayTimer.current) {
            clearTimeout(delayTimer.current)
            delayTimer.current = null
        }
    }

    const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (method === 'click') {
            setVisible(true)
        }
    }

    const onMouseEnter = () => {
        if (active && method === 'hover') {
            clearTimer()
            if (delay) {
                delayTimer.current = setTimeout(() => {
                    setVisible(true)
                }, delay)
            } else {
                setVisible(true)
            }
        }
    }

    const onMouseLeave = () => {
        if (method === 'hover') {
            clearTimer()
            setVisible(false)
        }
    }

    useImperativeHandle(ref, () => ({
        hide: () => {
            clearTimer()
            setVisible(false)
        }
    }), [])

    const computedClass = useMemo(() => {
        let name = styles.tooltip
        if (padding) name += ' ' + styles['with-padding']
        return name
    }, [])

    useEffect(() => {
        if (method === 'click') {
            const onClickWindow = (event: MouseEvent) => {
                setVisible(false)
            }
            window.document.addEventListener('click', onClickWindow)
            return () => {
                window.document.removeEventListener('click', onClickWindow)
            }
        }
        return () => {}
    }, [ method, visible ])

    return (
        <div
            className={styles.container}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {children}
            {visible && (
                <div className={computedClass} style={{ ...style, ...{ top: 32, left: -12, ...offset } }}>
                    {render()}
                    <div className={styles.arrow}>
                        <div></div>
                    </div>
                </div>
            )}
        </div>
    )

})

export default Tooltip
