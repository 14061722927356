import React, { createContext, useState, PropsWithChildren } from 'react'

export interface ShareControlContextValue {
    setRender(jsx: JSX.Element | null): void
}

const ShareControlContext = createContext<ShareControlContextValue>({
    setRender: () => null
})

const ShareControlProvider = ({ children }: PropsWithChildren<{}>) => {

    const [ render, setRender ] = useState<JSX.Element | null>(null)

    return (
        <ShareControlContext.Provider value={{ setRender }}>
            {children}
            {render}
        </ShareControlContext.Provider>
    )

}

export { ShareControlProvider }

export default ShareControlContext
