import React, { useMemo, PropsWithChildren } from 'react'
import { cn } from '@/utils/utils'
import styles from './styles.module.scss'

export type ButtonProps = {
    type?: 'button' | 'submit' | 'reset'
    black?: boolean
    orange?: boolean
    red?: boolean
    as?: 'button' | 'div'
    disabled?: boolean
    className?: string
    onClick?(e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>): void
}

const Button = ({ children, type = 'button', black = false, orange = false, red = false, as = 'button', disabled = false, className, onClick }: PropsWithChildren<ButtonProps>) => {

    const computedClass = useMemo(() => cn(
        styles.button,
        black ? styles.black : '',
        orange ? styles.orange : '',
        red ? styles.red : '',
        "h-11",
        "px-4",
        "bg-white",
        "text-sm",
        className
    ), [ black, orange, red, className ])

    if (as === 'button'){
        return (
            <button type={type} className={computedClass} onClick={!disabled ? onClick : undefined}>
                {children}
            </button>
        )
    } else {
        return (
            <div className={computedClass} onClick={!disabled ? onClick : undefined}>
                {children}
            </div>
        )
    }

}

export default Button
