import { useEffect, useRef, useState } from 'react'
import bold, { UserGroup } from '@/utils/bold-sdk'
import useAccount from '@/hooks/useAccount'
import useNotification from '@/hooks/useNotification'

type UseUserGroupsProps = {
    organizationId?: number
    includeUsers?: boolean
    search?: string
    searchOnly?: boolean
    sortBy?: 'name'
    sortDirection?: 'asc' | 'desc'
    size?: number
}

type UseUserGroupsReturnValue = {
    data: UserGroup[]
    loading: boolean
    offset: number
    fetch(offset?: number): Promise<void>
    loadMore(): Promise<void>
    update(data: UserGroup[]): void
}

const useUserGroups = ({ organizationId, includeUsers = false, search, searchOnly = false, sortBy, sortDirection, size = 20 }: UseUserGroupsProps): UseUserGroupsReturnValue => {

    const { data: account } = useAccount()
    const { handleError } = useNotification()
    const [ data, setData ] = useState<UserGroup[]>([])
    const [ loading, setLoading ] = useState(false)
    const offset = useRef(0)

    const fetch = async (offset: number = 0) => {
        try {
            setLoading(true)
            if (offset === 0) setData([])
            const { data: newData } = await bold.userGroup.getAll({
                organizationId,
                includeUsers,
                search: (search && search.length) ? search : undefined,
                sortBy,
                sortDirection,
                offset,
                size
            })
            if (offset > 0) setData([...data,...newData])
            else setData(newData)
        } catch (error) {
            handleError(error)
        } finally {
            setLoading(false)
        }
    }

    const loadMore = () => {
        offset.current += size
        return fetch(offset.current)
    }

    useEffect(() => {
        if (account) {
            // Make sure we're authenticated
            if (searchOnly && search && search.length > 0) fetch(0)
            else if (searchOnly) setData([])
            else if (!searchOnly) fetch(0)
        }
    }, [ account, organizationId, includeUsers, search, searchOnly, sortBy, sortDirection ])

    return { data, loading, offset: offset.current, fetch, loadMore, update: setData }

}

export default useUserGroups
