import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import bold, { AccountRichUser } from '@/utils/bold-sdk'
import useAccount from '@/hooks/useAccount'
import useNotification from '@/hooks/useNotification'
import Spinner from '@/components/spinner'
import { AlertCircleIcon } from 'lucide-react'
import { STORAGE_KEY } from '@/utils/constants'

export type OrganizationContextValue = {
    organization: AccountRichUser['organization'] | null
    accountUsers: AccountRichUser[]
    accountUser?: AccountRichUser | null
    setAccountUser: (accountUser: AccountRichUser | null, virtual?: boolean) => void
    setAccountUsers: (accountUsers: AccountRichUser[]) => void
}

export type OrganizationStorageValue = {
    id: number
    virtual: boolean
}

export type AccountUser = AccountRichUser & {
    virtual: boolean
}

const OrganizationContext = React.createContext<OrganizationContextValue>({
    organization: null,
    accountUsers: [],
    setAccountUsers: () => { void 0; },
    accountUser: undefined,
    setAccountUser: () => { void 0; }
})

const PortalAccessForbidden = React.lazy(() => import('@/components/portal-access-forbidden'))

const OrganizationProvider = ({ children }: PropsWithChildren) => {

    const { data: account } = useAccount()
    const { handleError } = useNotification()
    const [ accountUsers, setAccountUsers ] = useState<AccountRichUser[]>([])
	const [ accountUser, _setAccountUser ] = useState<AccountUser | null>()

    const setAccountUser = (accountUser: AccountRichUser | null, virtual: boolean = false) => {
        if (accountUser) {
            window.localStorage.setItem(STORAGE_KEY.ORGANIZATION, JSON.stringify({
                id: accountUser.organization.id,
                virtual
            }))
        } else {
            window.localStorage.removeItem(STORAGE_KEY.ORGANIZATION)
        }
        _setAccountUser(accountUser ? { ...accountUser, virtual } : null)
    }

    const getAccountUser = async () => {
        if (!account) throw new Error('Missing account data')
        let accountUser: AccountRichUser | null = null
        const json = window.localStorage.getItem(STORAGE_KEY.ORGANIZATION)
        let virtual: boolean = false
        let data: OrganizationStorageValue | null = null
        try { if (json) data = JSON.parse(json) } catch { }
        if (data && data.id !== undefined) {
            if (data.virtual) {
                try {
                    const { data: organization } = await bold.organization.get(data.id)
                    accountUser = {
                        id: -1,
                        organization,
                        permissions: [],
                        isSuperUser: true
                    }
                    virtual = true
                } catch (error) {
                    handleError(error)
                }
            }
            else {
                accountUser = account.users.find(user =>
                    user.organization.id === data?.id // matches org
                    && (user.organization.permissions.portal || !user.organization.personal) // org has portal access
                    && user.isSuperUser // user is org super user
                ) ?? null
            }
        }
        if (!accountUser) {
            // find first org with portal access
            accountUser = account.users.find(u => (u.organization.permissions.portal || !u.organization.personal) && u.isSuperUser) ?? null
        }
        setAccountUser(accountUser, virtual)
        setAccountUsers(account.users)
    }

    useEffect(() => {
        if (account) {
            getAccountUser()
        }
    }, [ account ])

    const organization = useMemo((): AccountRichUser['organization'] | undefined | null =>
        // If accountUser is undefined, organization should be so too.
        // This means data is still being fetched.
        accountUser === undefined ? undefined : accountUser?.organization ?? null
    , [ accountUser ])

    const hasPortalAccess = useMemo(() => organization || account?.isSystemAccount || account?.isSupportAccount, [ organization, account ])

    if (!hasPortalAccess) return <PortalAccessForbidden />

    if (organization === undefined) return <Spinner center />

    const value: OrganizationContextValue = {
        organization,
        accountUsers,
        accountUser,
        setAccountUser,
        setAccountUsers
    }

    return (
        <OrganizationContext.Provider value={value}>
            {accountUser?.virtual ? (
                <div className="flex items-center py-2 px-3 bg-orange-600 text-white">
                    <AlertCircleIcon size={18} className="me-2" />
                    Impersonating&nbsp;
                    <Link to={`/admin/organizations/${accountUser.organization.id}`} className="font-medium text-white hover:text-white">
                        {accountUser.organization.name}
                    </Link>
                </div>
            ) : null}
            {children}
        </OrganizationContext.Provider>
    )

}

export { OrganizationProvider }
export default OrganizationContext
