import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { UserGroup } from '@/utils/bold-sdk'
import ColorIcon from './color-icon'
import { ReactComponent as IconUsers } from '@/assets/icons/users.svg'
import styles from './styles.module.scss'

export type UserGroupIconNameProps = {
    as?: string
    userGroup: Partial<UserGroup> & { id: number }
    editable?: boolean
    iconSize?: number
    link?: boolean
    className?: string
    onClick?(): void
}

const UserGroupIconName = ({ as = 'span', userGroup, iconSize = 32, link = true, className, onClick = () => {} }: UserGroupIconNameProps) => {

    const Component = as as keyof JSX.IntrinsicElements

    const url = useMemo(() => `/user-groups/${userGroup.id}`, [ userGroup ])

    const computedClassName = useMemo(() => [
        styles.iconName,
        className
    ].join(' '), [ className ])

    return (
        <div
            className={computedClassName}
            onClick={onClick}
        >
            <ColorIcon id={userGroup.id} size={iconSize}>
                <IconUsers
                    width={iconSize * 0.56}
                    height={iconSize * 0.56}
                    className={styles.svg}
                />
            </ColorIcon>
            <div className={styles.name} style={{ marginInlineStart: iconSize * 0.3 }}>
                {link ? (
                    <Link to={url}><Component>{userGroup.name}</Component></Link>
                ) : (
                    <Component>{userGroup.name}</Component>
                )}
            </div>
        </div>
    )

}

export default UserGroupIconName
