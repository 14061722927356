import React, { useEffect, useRef, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Device, DeviceGroup, User, UserGroup } from '@/utils/bold-sdk'
import useLanguage from '@/hooks/useLanguage'
import useOrganization from '@/hooks/useOrganization'
import useDevices from '@/hooks/useDevices'
import useDeviceGroups from '@/hooks/useDeviceGroups'
import useUsers from '@/hooks/useUsers'
import useUserGroups from '@/hooks/useUserGroups'
import DeviceIconName from '@/components/icon-name/device-icon-name'
import DeviceGroupIconName from '@/components/icon-name/device-group-icon-name'
import UserIconName from '@/components/icon-name/user-icon-name'
import UserGroupIconName from '@/components/icon-name/user-group-icon-name'
import Spinner from '@/components/spinner'
import styles from './styles.module.scss'

type Result =
    { type: 'device', needle: string, data: Device } |
    { type: 'deviceGroup', needle: string, data: DeviceGroup } |
    { type: 'user', needle: string, data: User } |
    { type: 'userGroup', needle: string, data: UserGroup }

const Search = () => {

    const location = useLocation()
    const { language } = useLanguage()
    const { organization } = useOrganization()
    const [ value, setValue ] = useState<string | null>(null)
    const [ search, setSearch ] = useState<string>('')
    const devices = useDevices({ organizationId: organization?.id, search, searchOnly: true, size: 6 })
    const deviceGroups = useDeviceGroups({ organizationId: organization?.id, search, searchOnly: true, size: 6 })
    const users = useUsers({ organizationId: organization?.id, search, searchOnly: true, size: 6 })
    const userGroups = useUserGroups({ organizationId: organization?.id, search, searchOnly: true, size: 6 })
    const timeout = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            setValue(null)
            setSearch('')
        }
        if (search.length > 0) {
            window.document.addEventListener('click', handleDocumentClick)
        }
        return () => {
            window.document.removeEventListener('click', handleDocumentClick)
        }
    }, [ search ])

    useEffect(() => {
        // Reset search when route changes
        if (search !== '') setSearch('')
    }, [ location.pathname ])

    useEffect(() => {
        if (value !== null) {
            if (timeout.current) clearTimeout(timeout.current)
            timeout.current = setTimeout(() => {
                setSearch(value)
                timeout.current = null
            }, 600)
        }
    }, [ value ])

    const loading = useMemo(() =>
        devices.loading ||
        users.loading ||
        userGroups.loading
    , [ devices.loading, users.loading, userGroups.loading ])

    const results = useMemo<Result[]>(() => {
        const devicesMap: Result[] = devices.data.map(data => ({
            type: 'device',
            needle: data.name ?? '',
            data
        }))
        const deviceGroupsMap: Result[] = deviceGroups.data.map(data => ({
            type: 'deviceGroup',
            needle: data.name ?? '',
            data
        }))
        const usersMap: Result[] = users.data.map(data => ({
            type: 'user',
            needle: [data.firstName, data.lastName].join(' '),
            data
        }))
        const userGroupsMap: Result[] = userGroups.data.map(data => ({
            type: 'userGroup',
            needle: data.name,
            data
        }))
        return [
            ...devicesMap,
            ...deviceGroupsMap,
            ...usersMap,
            ...userGroupsMap
        ].sort((a, b) => a.needle.localeCompare(b.needle))
    }, [ devices.data, users.data, userGroups.data ])

    const renderResult = (value: Result, index: number, array: Result[]) => {
        return (
            <div className={styles.result}>
                {value.type === 'device' && <DeviceIconName device={value.data} />}
                {value.type === 'deviceGroup' && <DeviceGroupIconName deviceGroup={value.data} />}
                {value.type === 'user' && <UserIconName user={value.data} />}
                {value.type === 'userGroup' && <UserGroupIconName userGroup={value.data} />}
            </div>
        )
    }

    return (
        <div className={styles.searchContainer}>
            <input
                type="text"
                placeholder={language.searchGlobal}
                value={value ?? ''}
                className={styles.search}
                onChange={event => setValue(event.target.value)}
            />
            {loading && (
                <div className={styles.loading}>
                    <Spinner size={26} />
                </div>
            )}
            {results.length > 0 && (
                <div className={styles.results}>
                    {results.map(renderResult)}
                </div>
            )}
        </div>
    )

}

export default Search
