import React, { useMemo } from 'react'
import { cn } from '@/utils/utils'
import styles from './styles.module.scss'

type SpinnerProps = {
    size?: number
    color?: 'orange' | 'white'
    center?: boolean
    className?: string
}

const Spinner = ({ size = 36, color = 'orange', center = false, className }: SpinnerProps) => {

    const src = useMemo(() =>
        color === 'orange'
            ? '/assets/images/woosh.png'
            : '/assets/images/woosh-white.png'
    , [ color ])

    return (
        <div className={cn(styles.spinnerContainer,center ? styles.center : '', className)}>
            <div className={styles.spinner} style={{ width: size, height: size }}>
                <img src={src} alt="Bold Woosh" style={{ width: size, height: size }} />
            </div>
        </div>
    )

}

export default Spinner
