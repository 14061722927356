import React, { useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import useLanguage from '@/hooks/useLanguage'
import useAccount from '@/hooks/useAccount'
import useOrganization from '@/hooks/useOrganization'
import { ReactComponent as IconAngleDown } from '@/assets/icons/angle-down.svg'
import { ReactComponent as IconLock } from '@/assets/icons/lock.svg'
import { ReactComponent as IconLocks } from '@/assets/icons/locks.svg'
import { ReactComponent as IconHistory } from '@/assets/icons/history.svg'
import { ReactComponent as IconUser } from '@/assets/icons/user.svg'
import { ReactComponent as IconUsers } from '@/assets/icons/users.svg'
import { ReactComponent as IconUserSquare } from '@/assets/icons/user-square.svg'
import { ReactComponent as IconBracketsCurly } from '@/assets/icons/brackets-curly.svg'
import { ReactComponent as IconBuilding } from '@/assets/icons/building.svg'
import { ReactComponent as IconClose } from '@/assets/icons/close.svg'
import { cn } from '@/utils/utils'
import styles from './styles.module.scss'

export type Route = {
    name: string
    path: string
    icon?: JSX.Element
    visible?: boolean
}

export type Section = {
    id: string
    name: string
    routes: Route[]
    visible?: boolean
}

export type NavigationProps = {
    desktop?: boolean
    mobile?: boolean
    mobileVisible?: boolean
    onMobileClose?(): void
}

const Navigation = ({ desktop = true, mobile = false, mobileVisible = false, onMobileClose = () => {} }: NavigationProps) => {

    const location = useLocation()
    const { language } = useLanguage()
    const { data: account } = useAccount()
    const { organization, accountUser } = useOrganization()

    useMemo(() => {
        // Reset page title in case JS page title is not present
        document.title = process.env.REACT_APP_TITLE ?? ''
    }, [ location.pathname ])

    const type = useMemo(() => mobile ? 'mobile' : 'desktop', [ desktop, mobile ])

    const sections = useMemo<Array<Section & { visible?: boolean }>>(() => [
        {
            id: 'devices',
            name: language.devices,
            routes: [
                {
                    name: language.devices,
                    path: '/devices',
                    icon: <IconLock width={20} height={20} />
                },
                {
                    name: language.deviceGroupsShort,
                    path: '/device-groups',
                    icon: <IconLocks width={20} height={20} />,
                    visible: !organization?.personal
                },
                {
                    name: language.events,
                    path: '/device-events',
                    icon: <IconHistory width={20} height={20} />
                }
            ]
        },
        {
            id: 'users',
            name: language.users,
            routes: [
                {
                    name: language.users,
                    path: '/users',
                    icon: <IconUser width={20} height={20} />
                },
                {
                    name: language.userGroupsShort,
                    path: '/user-groups',
                    icon: <IconUsers width={20} height={20} />,
                    visible: !organization?.personal
                }
            ]
        },
        {
            id: 'organization',
            name: language.organization,
            routes: [
                {
                    name: language.developer,
                    path: '/organization/developer',
                    icon: <IconBracketsCurly width={20} height={20} />,
                    visible: organization?.permissions.integration
                }
            ],
            visible: organization?.permissions.integration && accountUser?.isSuperUser
        },
        {
            id: 'admin',
            name: language.admin,
            routes: [
                {
                    name: language.organizations,
                    path: '/admin/organizations',
                    icon: <IconBuilding width={20} height={20} />
                },
                {
                    name: language.accounts,
                    path: '/admin/accounts',
                    icon: <IconUserSquare width={20} height={20} />
                },
                {
                    name: language.devices,
                    path: '/admin/devices',
                    icon: <IconLock width={20} height={20} />
                }
            ],
            visible: account?.isSystemAccount || account?.isSupportAccount
        },
    ], [ account, organization, accountUser ])

    const [ unfoldedSections, setUnfoldedSections ] = useState<string[]>(() =>
        sections
            .map(section =>
                section.routes.filter(route =>
                    location.pathname.startsWith(route.path)
                ).length > 0 ? section.id : null
            )
            .filter(sectionId => sectionId !== null) as string[]
    )

    const unfoldAll = true

    const toggleSectionVisible = (sectionId: string, visible?: boolean) => {
        const newUnfoldedSections = [...unfoldedSections]
        if ((visible || visible === undefined) && !newUnfoldedSections.includes(sectionId))
            newUnfoldedSections.push(sectionId)
        else if ((visible === false || visible === undefined) && newUnfoldedSections.includes(sectionId))
            newUnfoldedSections.splice(newUnfoldedSections.indexOf(sectionId), 1)
        setUnfoldedSections(newUnfoldedSections)
    }

    const onClickSection = (sectionId: string, toggleVisible: boolean) => {
        toggleSectionVisible(sectionId, toggleVisible)
        if (type === 'mobile' && unfoldedSections.includes(sectionId)) onMobileClose()
    }

    const renderSection = (section: Section) => {
        const routes = section.routes.filter(r => r.visible !== false)
        const activeRoutes = routes.filter(route => location.pathname.startsWith(route.path))
        return (
            <div
                key={section.id}
                className={cn(styles.section, (unfoldAll || unfoldedSections.includes(section.id)) ? styles.unfolded : '', activeRoutes.length > 0 ? styles.activeRoutes : '')}
            >
                <div className={styles.item}>
                    <Link to={section.routes[0]?.path ?? '#'} onClick={() => onClickSection(section.id, true)}>
                        <h4>{section.name}</h4>
                    </Link>
                    {!unfoldAll && (
                        <div
                            className={styles.toggle}
                            onClick={event => {
                                event.stopPropagation()
                                toggleSectionVisible(section.id)
                            }}
                        >
                            <IconAngleDown width={22} height={22} />
                        </div>
                    )}
                </div>
                <ul>
                    {routes.map(route => renderRoute(route))}
                </ul>
            </div>

        )
    }

    const renderRoute = (route: Route) => {
        const active = location.pathname.startsWith(route.path)
        return (
            <li key={route.path} className={active ? styles.active : ''}>
                <Link to={route.path} onClick={type === 'mobile' ? onMobileClose : undefined}>
                    {route.icon}
                    <h4>{route.name}</h4>
                </Link>
            </li>
        )
    }

    return (
        <div className={cn(styles.navigation, type === 'mobile' ? styles.mobile : '', mobileVisible ? styles.visible : '')}>
            {type === 'mobile' && (
                <div className={styles.iconClose} onClick={onMobileClose}>
                    <IconClose width={22} height={22} />
                </div>
            )}
            <ul>
                {sections
                    .filter(r => r.visible !== false)
                    .map(section => renderSection(section))
                }
            </ul>
        </div>
    )

}

export default Navigation
