import React, { createContext, useState, PropsWithChildren } from 'react'
import Modal, { ModalProps } from '@/components/modal'
import useLanguage from '@/hooks/useLanguage'

export type ConfirmModalContextValue = {
    confirm(options: ConfirmOptions): Promise<boolean>
}

export type ConfirmOptions = {
    title: string
    text: string
}

const ConfirmModalContext = createContext<ConfirmModalContextValue>({
    confirm: async () => false
})

const ConfirmModalProvider = ({ children }: PropsWithChildren<{}>) => {

    const { language } = useLanguage()
    const [ props, setProps ] = useState<ModalProps | null>(null)
    const [ text, setText ] = useState<string>('')

    const confirm = async ({ title, text }: ConfirmOptions): Promise<boolean> => {
        const confirmed = await new Promise<boolean>(resolve => {
            setText(text)
            setProps({
                visible: true,
                title,
                actions: [
                    { name: language.confirm, style: 'red', onClick: () => resolve(true) },
                    { name: language.cancel, onClick: () => resolve(false) }
                ]
            })
        })
        setText('')
        setProps(null)
        return confirmed
    }

    const value: ConfirmModalContextValue = {
        confirm
    }

    return (
        <ConfirmModalContext.Provider value={value}>
            {children}
            {props !== null && <Modal {...props}>{text}</Modal>}
        </ConfirmModalContext.Provider>
    )

}

export { ConfirmModalProvider }

export default ConfirmModalContext
