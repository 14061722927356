import React, { useState, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import useInfinityScroll from '@/hooks/useInfinityScroll'
import Navigation from '@/components/navigation'
import UserMenu from './user-menu'
import Search from '@/components/search'
import { MenuIcon } from 'lucide-react'
import logo from '@/assets/bold-logo.svg'
import styles from './styles.module.scss'

export type ContainerProps = {
    center?: boolean
}

const Container = ({ children, center = true }: PropsWithChildren<ContainerProps>) => {

    const { handleScroll } = useInfinityScroll()
    const [ mobileNavigationVisible, setMobileNavigationVisible ] = useState(false)

    return (
        <div className={styles.container}>
            <nav className={styles.sidebar}>
                <div className={styles.header}>
                    <Link to="/">
                        <img className={styles.logo} src={logo} alt="Bold" />
                    </Link>
                </div>
                <Navigation desktop />
            </nav>
            <header className={styles.header}>
                <div className={styles.headerLeft}>
                    <div
                        className={styles.mobileNavButton}
                        onClick={() => setMobileNavigationVisible(!mobileNavigationVisible)}
                    >
                        <MenuIcon size={28} />
                    </div>
                    <Search />
                </div>
                <UserMenu />
            </header>
            <main className={styles.main} onScroll={handleScroll}>
                {center ? (
                    <div className={styles.center}>
                        {children}
                    </div>
                ) : (
                    children
                )}
            </main>
            <Navigation
                mobile
                mobileVisible={mobileNavigationVisible}
                onMobileClose={() => setMobileNavigationVisible(false)}
            />
        </div>
    )

}

export default Container
