interface String {
    interpolate(...entries: string[]): string
}


Object.defineProperty(String.prototype, 'interpolate', {
    value(...entries: string[]) {
        let str = this
        for (let i = 0; i < entries.length; i++) {
            str = str.replace(`{${i}}`, entries[i])
        }
        return str
    }
})
