export const arrayContainsStartingWith = (predArr: (string | RegExp)[], str: string): boolean =>
    predArr.find(pred => {
        if (pred instanceof RegExp) {
            return pred.test(str);
        } else {
            return str.startsWith(pred);
        }
    }) !== undefined

export function interpolateString(input: string, ...values: (string | number)[]) {
    if (!input) return ''
    for (let i = 0; i < values.length; i++){
        input = input.replace("{"+i+"}", values[i].toString())
    }
    return input
}

export function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}
