import { useEffect, useRef, useState } from 'react'
import bold, { DeviceGroup } from '@/utils/bold-sdk'
import useAccount from '@/hooks/useAccount'
import useNotification from '@/hooks/useNotification'

type UseDeviceGroupsProps = {
    organizationId?: number
    deviceType?: 'Gateway' | 'Keyfob' | 'Lock'
    search?: string
    searchOnly?: boolean
    sortBy?: 'name'
    sortDirection?: 'asc' | 'desc'
    size?: number
    includeDevices?: boolean
}

type UseDeviceGroupsReturnValue = {
    data: DeviceGroup[]
    loading: boolean
    offset: number
    fetch(offset?: number): Promise<void>
    loadMore(): Promise<void>
    update(data: DeviceGroup[]): void
}

const useDeviceGroups = ({ organizationId, search, searchOnly = false, sortBy, sortDirection, size = 20, includeDevices = false }: UseDeviceGroupsProps): UseDeviceGroupsReturnValue => {

    const { data: account } = useAccount()
    const { handleError } = useNotification()
    const [ data, setData ] = useState<DeviceGroup[]>([])
    const [ loading, setLoading ] = useState(false)
    const offset = useRef(0)

    const fetch = async (offset: number = 0) => {
        try {
            setLoading(true)
            if (offset === 0) setData([])
            let { data: newData } = await bold.deviceGroup.getAll({
                organizationId,
                search: (search && search.length) ? search : undefined,
                includeDevices: false,
                sortBy,
                sortDirection,
                offset,
                size
            })
            if (offset > 0) setData([...data,...newData])
            else setData(newData)
        } catch (error) {
            handleError(error)
        } finally {
            setLoading(false)
        }
    }

    const loadMore = () => {
        offset.current += size
        return fetch(offset.current)
    }

    useEffect(() => {
        if (account) {
            // Make sure we're authenticated
            if (searchOnly && search && search.length > 0) fetch(0)
            else if (searchOnly) setData([])
            else if (!searchOnly) fetch(0)
        }
    }, [ account, organizationId, search, searchOnly, sortBy, sortDirection, includeDevices ])

    return { data, loading, offset: offset.current, fetch, loadMore, update: setData }

}

export default useDeviceGroups
