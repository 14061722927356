import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { DeviceGroup } from '@/utils/bold-sdk'
import { ReactComponent as IconLocks } from '@/assets/icons/locks.svg'
import styles from './styles.module.scss'

export type DeviceGroupIconNameProps = {
    as?: string
    deviceGroup: Partial<DeviceGroup> & { id: number }
    iconSize?: number
    link?: boolean
    className?: string
    onClick?(): void
}

const DeviceGroupIconName = ({ as = 'span', deviceGroup, iconSize = 32, link = true, className, onClick = () => {} }: DeviceGroupIconNameProps) => {

    const Component = as as keyof JSX.IntrinsicElements

    const url = useMemo(() => `/device-groups/${deviceGroup.id}`, [ deviceGroup.id ])

    const computedClassName = useMemo(() => {
        let cn = styles.iconName
        if (className) cn += ' ' + className
        return cn
    }, [ className ])

    return (
        <div
            className={computedClassName}
            onClick={onClick}
        >
            <div
                className={styles.iconDeviceGroup}
                style={{
                    width: iconSize,
                    height: iconSize
                }}
            >
                <IconLocks
                    width={iconSize * 0.56}
                    height={iconSize * 0.56}
                    className={styles.svg}
                />
            </div>
            <div className={styles.name} style={{ marginInlineStart: iconSize * 0.3 }}>
                {link ? (
                    <Link to={url}><Component>{deviceGroup.name}</Component></Link>
                ) : (
                    <Component>{deviceGroup.name}</Component>
                )}
            </div>
        </div>
    )

}

export default DeviceGroupIconName
