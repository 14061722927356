import { useEffect, useRef } from 'react'
import { emitter } from '@/utils/events'

const useInfinityScroll = (callback?: () => any, disabled: boolean = false) => {

    const eventName = 'scrollendreached'
    const prevScrollY = useRef(0)
    const cooldown = useRef(false)

    const handleScroll = (event: any) => {
        const scrollY = event.target.offsetHeight + event.target.scrollTop
        const diff = Math.abs(prevScrollY.current - scrollY)
        if (!cooldown.current && diff >= 100 && !disabled && scrollY >= event.target.scrollHeight - 20) {
            emitter.emit(eventName)
            prevScrollY.current = scrollY
            cooldown.current = true
            setTimeout(() => cooldown.current = false, 250)
        }
    }

    useEffect(() => {
        if (callback) {
            emitter.addListener(eventName, callback)
            return () => { emitter.removeListener(eventName, callback) }
        }
        return () => {}
    }, [ callback ])

    return { handleScroll }

}

export default useInfinityScroll
